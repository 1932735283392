import { isValueIncluded } from '@emobg/web-utils';

export const CONTENTS_GROUP = {
  general: 'general',
  bookingCreation: 'booking_creation',
  twoFA: '2FA',
  bookingsInfo: 'bookings_info',
  registration: 'registration',
  accountManagement: 'account_management',
  companyBookingsInfo: 'company_bookings_info',
  companyAccountManagement: 'company_account_management',
  login: 'login',
};

export const URLS_TO_CONTENT = {
  '/': CONTENTS_GROUP.general,
  '/search-location-results': CONTENTS_GROUP.bookingCreation,
  '/verification': CONTENTS_GROUP.twoFA,
  '/bookings/my-bookings/active': CONTENTS_GROUP.bookingsInfo,
  '/bookings/my-bookings/history': CONTENTS_GROUP.bookingsInfo,
  '/signup/company/step1': CONTENTS_GROUP.registration,
  '/signup/company/step2': CONTENTS_GROUP.registration,
  '/signup/company/step3': CONTENTS_GROUP.registration,
  '/signup/user/step1': CONTENTS_GROUP.registration,
  '/signup/user/step2': CONTENTS_GROUP.registration,
  '/signup/user/step3': CONTENTS_GROUP.registration,
  '/account-creation-confirmation-modal': CONTENTS_GROUP.registration,
  '/email-verification': CONTENTS_GROUP.twoFA,
  '/account-settings/personal-information': CONTENTS_GROUP.accountManagement,
  '/confirm-cancel-account-modal': CONTENTS_GROUP.accountManagement,
  '/account-settings/payment-methods': CONTENTS_GROUP.accountManagement,
  '/account-settings/driving-license': CONTENTS_GROUP.accountManagement,
  '/account-settings/badge': CONTENTS_GROUP.accountManagement,
  '/personal-profile/insurances': CONTENTS_GROUP.accountManagement,
  '/personal-profile/insurance/annual-insurance': CONTENTS_GROUP.accountManagement,
  '/personal-profile/invoices': CONTENTS_GROUP.accountManagement,
  '/personal-profile/notifications': CONTENTS_GROUP.accountManagement,
  '/personal-profile/promotions': CONTENTS_GROUP.accountManagement,
  '/personal-profile/tariffs': CONTENTS_GROUP.accountManagement,
  '/add-payment-method-modal': CONTENTS_GROUP.accountManagement,
  '/communication-preferences': CONTENTS_GROUP.accountManagement,
  '/invite-a-friend': CONTENTS_GROUP.accountManagement,
  '/bookings/confirmation-modal': CONTENTS_GROUP.bookingCreation,
  '/bookings/my-bookings/logbook': CONTENTS_GROUP.companyBookingsInfo,
  '/employee-bookings/': CONTENTS_GROUP.companyBookingsInfo,
  '/company-admin/employees': CONTENTS_GROUP.companyAccountManagement,
  '/employee-bookings/booking-requests': CONTENTS_GROUP.companyBookingsInfo,
  '/reset-password-settings/': CONTENTS_GROUP.login,
  '/business-profile/carpooling': CONTENTS_GROUP.companyBookingsInfo,
  '/company-admin/invoices': CONTENTS_GROUP.companyAccountManagement,
  '/company-admin/fleet': CONTENTS_GROUP.companyAccountManagement,
  '/company-admin/booking-overview': CONTENTS_GROUP.companyBookingsInfo,
  '/company-admin/reporting': CONTENTS_GROUP.companyAccountManagement,
  '/company-admin/settings': CONTENTS_GROUP.companyAccountManagement,
  '/business-profile/notifications': CONTENTS_GROUP.companyAccountManagement,
  '/business-profile/cost-allocations': CONTENTS_GROUP.companyAccountManagement,
};

export const dynamicUrlsToContent = [
  { contentGroup: CONTENTS_GROUP.bookingCreation, criteria: '/bookings/summary/', criteriaFn: isValueIncluded },
];
