import split from 'lodash/split';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import {
  DELIMITERS, getValue, isNilValue, rehydrate,
} from '@emobg/web-utils';
import * as UserDataModule from '@/vue/stores/UserData/UserDataStore';
import * as ProfileModule from '@Profile/store';
import * as AccountModule from '@/stores/User/Account/AccountModule';
import * as UserModule from '@/stores/User/UserModule';
import { useAuth } from '@/composable/Api/Auth/auth';
import { MFA } from '@/constants/mfa';
import { PLATFORM_ANALYTICS } from '@/constants/app';
import config from '@/config';

import { dynamicUrlsToContent, URLS_TO_CONTENT } from './eventsMap.const';

const currentEnvironment = getValue(config, 'data.environment');

const { idpUuid } = useAuth();

const lookContentGroupByDynamicUrl = pagePath => getValue(
  dynamicUrlsToContent.find(({ criteria, criteriaFn }) => criteriaFn(pagePath, criteria)),
  'contentGroup',
  null,
);

const getContentGroup = pagePath => (pagePath in URLS_TO_CONTENT
  ? URLS_TO_CONTENT[pagePath]
  : lookContentGroupByDynamicUrl(pagePath));

export const composeUserTrackingData = ({
  trackingId, userCompanyName, currentProfile, country,
}) => ({
  user_uuid: trackingId,
  company_name: userCompanyName,
  user_profile: currentProfile,
  country,
});

export const getUserTrackingDataByGetters = ({ getters }) => ({
  user_uuid: getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userUuid}`],
  status: getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userStatus}`],
  company_name: getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userCompanyName}`],
  user_profile: getters[`${ProfileModule.nameSpace}/${ProfileModule.GETTERS.getCurrentProfileAlias}`],
  country: getters[`${UserModule.NAMESPACE}/${AccountModule.NAMESPACE}/${AccountModule.GETTERS.getUserAddressCountry}`],
  dl_issued_country: getters[`${UserModule.NAMESPACE}/${AccountModule.NAMESPACE}/${AccountModule.GETTERS.getUserDLIssuedCountry}`],
  creation_date: getters[`${UserModule.NAMESPACE}/${AccountModule.NAMESPACE}/${AccountModule.GETTERS.getUserCreationDate}`],
});

export const getPageTrackingData = ({
  country, language, newRoute, document, referrer, location = {},
}) => omitBy({
  location: getValue(location, 'href'),
  page_path: getValue(newRoute, 'fullPath'),
  name: getValue(newRoute, 'name').replaceAll('.', '_'),
  title: getValue(document, 'title'),
  content_group: getContentGroup(getValue(newRoute, 'fullPath')),
  environment: currentEnvironment,
  platform: PLATFORM_ANALYTICS,
  language,
  country,
  referrer,
}, isNilValue);

const getPersistedDataKey = () => `${MFA.extraUserMetadata}-${last(split(idpUuid, DELIMITERS.pipe))}`;

export const setUserPersistedData = ({ value }) => rehydrate.set(getPersistedDataKey(), 'data', value);

export const getUserPersistedData = () => rehydrate.get(getPersistedDataKey(), 'data');

export const getUserHasCompany = () => rehydrate.get(getPersistedDataKey(), `data.${MFA.userHasCompany}`);

export const getUserEmail = () => rehydrate.get(getPersistedDataKey(), `data.${MFA.userEmail}`);

export const setPersistentDataForUser = userData => {
  if (userData) {
    setUserPersistedData({
      value: {
        ...getValue(getUserPersistedData(), 'data'),
        [MFA.userHasCompany]: !isEmpty(getValue(userData, 'company')),
      },
    });
  }
};
